
import { Options, Vue } from "vue-class-component";
import { AuthController } from "@/app/ui/controllers/AuthController";
import Footer from "@/app/ui/components/footer/index.vue";
import { defineAsyncComponent } from "@vue/runtime-core";
import { AccountController } from "../../controllers/AccountController";
import {
  convertPhoneNumber,
  maxLengthPhoneNumber,
  validateInputEmail,
  validatePhoneNumber
} from "@/app/infrastructures/misc/Utils";
const ModalOtherMethod = defineAsyncComponent(() =>
  import("./component/modal-method-verification.vue")
);

@Options({
  components: {
    Footer,
    ModalOtherMethod
  }
})
export default class ForgotEmail extends Vue {
  mounted() {
    this.initPage();
  }

  initPage() {
    if (!this.emailOrUsername || !this.dataProfile.account_type) {
      this.$router.replace("/");
    }
  }

  get typeFormValue() {
    if (this.isInternal) return "phone_number_account";
    else if (this.isClient) return "client_id";
    return "partner_id";
  }
  form = {
    email: ""
  };
  typeForm = {
    caption: this.isInternal ? "no. handphone" : "ID Partner/Klien",
    form: "",
    type: this.isInternal ? "text" : "number",
    placeholder: this.isInternal
      ? "Masukkan no. handphone Anda"
      : "Masukkan ID Partner/Klien",
    value: this.typeFormValue
  };
  showError = {
    form: false
  };
  isOpen = false;
  showModalOtherMethod = false;

  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }

  get isInternal() {
    return this.dataProfile.account_type === "internal";
  }

  get isClient() {
    return this.dataProfile.account_type === "client";
  }

  get contact() {
    const actor = this.dataProfile.account_type;
    if (actor === "partner") {
      return "whatsapp +62 851-7301-4021 untuk POS & via salesforce untuk Consolidator dan Subconsolidator";
    } else if (actor === "client") {
      return "Account Executive & Account Manager";
    } else {
      return "via salesforce";
    }
  }

  methodsPartnerClient = [
    {
      title: "ID Partner/Klien",
      value: this.isClient ? "client_id" : "partner_id",
      caption: "ID Partner/Klien",
      type: "number",
      placeholder: "Masukkan ID Partner/Klien"
    },
    {
      title: "Kode Partner/Klien",
      value: this.isClient ? "client_code" : "partner_code",
      caption: "Kode Partner/Klien",
      type: "text",
      placeholder: "Masukkan Kode Partner/Klien"
    },
    {
      title: "Email",
      value: "email_account",
      caption: "email",
      type: "email",
      placeholder: "e.g email@gmail.com"
    },
    {
      title: "No. Handphone",
      value: "phone_number_account",
      caption: "no. handphone",
      type: "text",
      placeholder: "Masukkan no. handphone Anda"
    },
    {
      title: "Email Partner/Klien",
      value: this.isClient ? "email_client" : "email_partner",
      caption: "email Partner/Klien",
      type: "email",
      placeholder: "e.g email@gmail.com"
    },
    {
      title: "No. Handphone Partner/Klien",
      value: this.isClient ? "phone_number_client" : "phone_number_partner",
      caption: "no. handphone Partner/Klien",
      type: "text",
      placeholder: "Masukkan no. handphone Anda"
    }
  ];

  errorFormEmail(str: string) {
    return !validateInputEmail(str);
  }

  maxLengthPhoneNumber(phone: string) {
    return maxLengthPhoneNumber(phone);
  }

  validatePhoneNumber(str: string) {
    return validatePhoneNumber(str);
  }

  convertPhoneNumber(val: string) {
    return convertPhoneNumber(val);
  }

  formatNumber(value: string) {
    return value.replace(/\D+|\+/g, "");
  }

  //Error Internet or System
  get isErrorInternetOrSystem() {
    return AuthController.isErrorInternetOrSystem;
  }
  //Error Cause
  get errorCause() {
    return AuthController.errorCause;
  }

  handleErrorClose() {
    AuthController.handleError();
  }

  handleError() {
    AuthController.setError(false);
    AuthController.setVerificationAcountErrorMessage("");
  }

  get captionError() {
    return AuthController.verificationAcountErrorMessage;
  }

  get isError() {
    return AuthController.isError;
  }

  get emailOrUsername() {
    return this.dataProfile.email;
  }

  get isLoading() {
    return AuthController.isLoading;
  }

  submitVerification() {
    AuthController.verificationAccount({
      emailOrUsername: this.emailOrUsername,
      question: this.typeForm.value,
      answer: this.typeForm.value.includes("phone")
        ? this.convertPhoneNumber(this.typeForm.form)
        : this.typeForm.form
    });
  }

  closeModalOtherMethod() {
    this.showModalOtherMethod = !this.showModalOtherMethod;
  }

  onClickMethodVerification(key: any) {
    this.closeModalOtherMethod();
    this.typeForm.form = "";
    this.typeForm.caption = key.title;
    this.typeForm.type = key.type;
    this.typeForm.placeholder = key.placeholder;
    this.typeForm.value = key.value;
    this.handleError();
  }

  onClickMethodInternal() {
    this.typeForm.form = "";
    this.typeForm.caption = this.typeForm.value.includes("phone")
      ? "email"
      : "no. handphone";
    this.typeForm.type = this.typeForm.value.includes("phone")
      ? "email"
      : "text";
    this.typeForm.placeholder = this.typeForm.value.includes("phone")
      ? "e.g email@gmail.com"
      : "Masukkan no. handphone Anda";
    this.typeForm.value = this.typeForm.value.includes("phone")
      ? "email_account"
      : "phone_number_account";
    this.handleError();
  }
}
