
import { getToken } from "@/app/infrastructures/misc/Cookies";
import { Vue, Options } from "vue-class-component";

@Options({
  props: {
    modelValue: {
      required: true
    },
    isModal: {
      default: true,
      type: Boolean
    },
    message: {
      default: "Permintaan sedang diproses",
      type: String
    }
  }
})
export default class Loading extends Vue {
  get isAuth() {
    return getToken();
  }
}
