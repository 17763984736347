<template>
  <div class="mx-auto max-w-md mt-16 font-montserrat">
    <img
      src="@/app/ui/assets/svg/logo-genesis.svg"
      class="h-12"
      alt="logo-genesis"
    />
    <p class="text-2xl font-montserrat font-bold text-custom-100 mt-6 mb-8">
     Reset Password
    </p>
    <p class="font-montserrat text-sm font-bold mt-4 text-custom-100">
      New Password
    </p>
    <div class="inline-block relative w-full">
      <input
        :type="newPasswordType"
        v-model="newPassword"
        @input="checkPassword"
        placeholder="Input new password"
        class="w-full mt-2 h-10 pl-4 border shadow-md font-montserrat focus:outline-none"
      />
      <button
        @click="showNewPassword"
        class="cursor-pointer absolute inset-y-0 right-0 flex items-center px-6 pt-2 text-gray-500 focus:outline-none"
      >
        <Icons
          v-if="iconType === 'eye'"
          name="eye"
          class="h-6 text-gray-lp-600"
        />
        <Icons v-else name="invisible" class="h-6 text-gray-lp-600" />
      </button>
    </div>
    <p class="font-montserrat text-sm font-bold mt-4 text-custom-100">
     Verify New Password
    </p>
    <div class="inline-block relative w-full">
      <input
        :type="confirmPasswordType"
        v-model="confirmPassword"
        @input="checkPassword"
        placeholder="Input verify new password"
        class="w-full mt-2 h-10 pl-4 border shadow-md font-montserrat focus:outline-none"
        :class="{
          'border-red-lp-100': !validPassword && confirmPassword
        }"
      />
      <button
        @click="showConfirmPassword"
        class="cursor-pointer absolute inset-y-0 right-0 flex items-center px-6 pt-2 text-gray-500 focus:outline-none"
      >
        <Icons
          v-if="iconTypeConfirm === 'eye'"
          name="eye"
          class="h-6 text-gray-lp-600"
        />
        <Icons v-else name="invisible" class="h-6 text-gray-lp-600" />
      </button>
    </div>
    <div class="my-4 mx-4">
      <div class="flex flex-row space-x-4 my-2">
        <Icons
          v-if="containsEightCharacters"
          name="check-circle"
          class="text-green-lp-100"
        />
        <Icons
          v-if="!containsEightCharacters && newPassword"
          name="x-circle"
          class="text-red-lp-400"
        />
        <Icons
          v-if="!newPassword"
          name="check-circle"
          class="text-gray-lp-500"
        />
        <p class="text-sm opacity-50">Must be at least 8 characters long</p>
      </div>
      <div class="flex flex-row space-x-4 my-2">
        <Icons
          v-if="containsNumber"
          name="check-circle"
          class="text-green-lp-100"
        />
        <Icons
          v-if="!containsNumber && newPassword"
          name="x-circle"
          class="text-red-lp-400"
        />
        <Icons
          v-if="!newPassword"
          name="check-circle"
          class="text-gray-lp-500"
        />
        <p class="text-sm opacity-50">Must contain a number</p>
      </div>
      <div class="flex flex-row space-x-4 my-2">
        <Icons
          v-if="containsUppercase"
          name="check-circle"
          class="text-green-lp-100"
        />
        <Icons
          v-if="!containsUppercase && newPassword"
          name="x-circle"
          class="text-red-lp-400"
        />
        <Icons
          v-if="!newPassword"
          name="check-circle"
          class="text-gray-lp-500"
        />
        <p class="text-sm opacity-50">
          Must contain an uppercase letter
        </p>
      </div>
      <div class="flex flex-row space-x-4 my-2">
        <Icons
          v-if="!containsGenesis && newPassword"
          name="check-circle"
          class="text-green-lp-100"
        />
        <Icons v-if="containsGenesis" name="x-circle" class="text-red-lp-400" />
        <Icons
          v-if="!newPassword"
          name="check-circle"
          class="text-gray-lp-500"
        />
        <p class="text-sm opacity-50">
          Must verify new password
        </p>
      </div>
      <div class="flex flex-row space-x-4 my-2">
        <Icons
          v-if="validPassword"
          name="check-circle"
          class="text-green-lp-100"
        />
        <Icons
          v-if="!validPassword && confirmPassword"
          name="x-circle"
          class="text-red-lp-400"
        />
        <Icons
          v-if="!confirmPassword"
          name="check-circle"
          class="text-gray-lp-500"
        />
        <p class="text-sm opacity-50">Confirm new password</p>
      </div>
    </div>
    <button
      type="submit"
      class="text-white py-2 my-4 font-bold rounded-md focus:outline-none bg-gray-lp-600 w-full"
      disabled
      v-if="
        !validPassword ||
          !containsEightCharacters ||
          !containsNumber ||
          !containsUppercase ||
          containsGenesis
      "
    >
      <span>Reset Password</span>
    </button>

    <button
      type="submit"
      class="text-white py-2 my-4 font-bold rounded-md focus:outline-none bg-red-lp-100 w-full"
      v-else
      @click="submit()"
    >
      Reset Password
    </button>
  </div>
  <Footer />
  <Modal
    v-if="isOpen"
    :onSubmit="onClose"
    title="Password Changed!"
    message="Your password has been changed"
    image="password_saved"
    textSuccess="OK"
  />
  <!--    loading   -->
  <loading v-model="loadingModal" />
  <!--    modal error   -->
  <Modal
    v-if="errorModal"
    :textCancel="errorTextCancel"
    :onClose="closeErrorModal"
    :onSubmit="errorOnSubmit"
    :textSuccess="errorTextSuccess"
    :title="errorEntities.title"
    :message="errorEntities.message"
    :image="errorEntities.image"
  />
</template>

<script>
import { Vue, Options } from "vue-class-component";
import { AuthController } from "@/app/ui/controllers/AuthController";
import Footer from "@/app/ui/components/footer/index.vue";
import router from "../../router";
import Loading from "@/app/ui/components/loading/index.vue";
import { MainAppController } from "@/app/ui/controllers/MainAppController";

@Options({
  name: "ResetPasswordPage",
  components: {
    Footer,
    Loading
  }
})
export default class ResetPassword extends Vue {
  newPassword = "";
  confirmPassword = "";
  passwordLength = 0;
  containsEightCharacters = false;
  containsNumber = false;
  containsUppercase = false;
  containsGenesis = false;
  validPassword = false;

  newPasswordType = "password";
  confirmPasswordType = "password";
  iconType = "eye-slash";
  iconTypeConfirm = "eye-slash";

  get isOpen() {
    return AuthController.openModalSuccess;
  }

  submit() {
    AuthController.changePassword({
      email: this.$route.query.email,
      password: this.newPassword,
      token: this.$route.query.token
    });
  }

  onClose() {
    AuthController.setModalSuccess(false);
    router.push("/");
  }

  showNewPassword() {
    if (this.newPasswordType === "password" && this.iconType === "eye-slash") {
      this.newPasswordType = "text";
      this.iconType = "eye";
    } else {
      this.newPasswordType = "password";
      this.iconType = "eye-slash";
    }
  }

  showConfirmPassword() {
    if (
      this.confirmPasswordType === "password" &&
      this.iconTypeConfirm === "eye-slash"
    ) {
      this.confirmPasswordType = "text";
      this.iconTypeConfirm = "eye";
    } else {
      this.confirmPasswordType = "password";
      this.iconTypeConfirm = "eye-slash";
    }
  }

  checkPassword() {
    this.passwordLength = this.newPassword.length;

    this.containsEightCharacters = this.passwordLength >= 8;

    this.containsNumber = /\d/.test(this.newPassword);
    this.containsUppercase = /[A-Z]/.test(this.newPassword);
    this.containsGenesis = /^genesis123/gi.test(this.newPassword);
    this.validPassword =
      this.containsEightCharacters === true &&
      this.containsNumber === true &&
      this.containsUppercase === true &&
      this.newPassword === this.confirmPassword;
  }

  // loading
  get loadingModal() {
    return MainAppController.loadingModal;
  }

  // error modal
  get errorModal() {
    return MainAppController.errorMessageModal;
  }
  get errorEntities() {
    return MainAppController.errorMessageEntities;
  }
  get errorTextCancel() {
    return this.errorEntities.onTryAgain ? "Back" : "";
  }
  get errorTextSuccess() {
    return this.errorEntities.onTryAgain ? "Try Again" : "OK";
  }
  get errorOnSubmit() {
    return this.errorEntities.onTryAgain
      ? this.errorEntities.onTryAgain
      : this.closeErrorModal;
  }
  closeErrorModal() {
    this.errorEntities.onClose && this.errorEntities.onClose();
    MainAppController.closeErrorMessage();
  }
}
</script>
